import React, {Component} from "react";
import {Router} from "@reach/router";
import NotFound from "./pages/NotFound.js";
import Skeleton from "./pages/Skeleton.js";

import "../utilities.css";

import {get, post} from "../utilities";

/**
 * Define the "App" component as a class.
 */
class App extends Component {
    // makes props available in this component
    constructor(props) {
        super(props);
        this.state = {
            userId: undefined,
        };
    }

    componentDidMount() {
        get("/api/whoami").then((user) => {
            if (user._id) {
                // they are registed in the database, and currently logged in.
                this.setState({userId: user._id});
            }
        });
    }

    handleLogin = (res) => {
        const userToken = res.tokenObj.id_token;
        post("/api/login", {token: userToken}).then((user) => {
            this.setState({userId: user._id});
        });
        console.log(`Logged in as ${res.profileObj.name}`);
    };

    handleLogout = () => {
        this.setState({userId: undefined});
        post("/api/logout");
    };

    render() {
        return (
            <>
                <Router>
                    <Skeleton
                        path="/"
                        handleLogin={this.handleLogin}
                        handleLogout={this.handleLogout}
                        userId={this.state.userId}
                    />
                    <NotFound default/>
                </Router>
            </>
        );
    }
}

export default App;
