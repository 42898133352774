/**
 * Utility functions to make API requests.
 * By importing this file, you can use the provided get and post functions.
 * You shouldn't need to modify this file, but if you want to learn more
 * about how these functions work, google search "Fetch API"
 *
 * These functions return promises, which means you should use ".then" on them.
 * e.g. get('/api/foo', { bar: 0 }).then(res => console.log(res))
 */
import Geocode from "react-geocode";
const util = require("util");

// ex: formatParams({ some_key: "some_value", a: "b"}) => "some_key=some_value&a=b"
function formatParams(params) {
  // iterate of all the keys of params as an array,
  // map it to a new array of URL string encoded key,value pairs
  // join all the url params using an ampersand (&).
  return Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
}

// convert a fetch result to a JSON object with error handling for fetch and json errors
function convertToJSON(res) {
  if (!res.ok) {
    throw `API request failed with response status ${res.status} and text: ${res.statusText}`;
  }

  return res
    .clone() // clone so that the original is still readable for debugging
    .json() // start converting to JSON object
    .catch((error) => {
      // throw an error containing the text that couldn't be converted to JSON
      return res.text().then((text) => {
        throw `API request's result could not be converted to a JSON object: \n${text}`;
      });
    });
}

// Helper code to make a get request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function get(endpoint, params = {}) {
  const fullPath = endpoint + "?" + formatParams(params);
  return fetch(fullPath)
    .then(convertToJSON)
    .catch((error) => {
      // give a useful error message
      throw `GET request to ${fullPath} failed with error:\n${error}`;
    });
}

// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function post(endpoint, params = {}) {
  return fetch(endpoint, {
    method: "post",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(params),
  })
    .then(convertToJSON) // convert result to JSON object
    .catch((error) => {
      // give a useful error message
      throw `POST request to ${endpoint} failed with error:\n${error}`;
    });
}

// Check if two arrays are equal
export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => {
      if (typeof val === "object" && typeof b[index] === "object") {
        return objectEquals(val, b[index]);
      } else if (Array.isArray(val) && Array.isArray(b[index])) {
        return arrayEquals(val, b[index]);
      } else {
        return val == b[index];
      }
    })
  );
}

// Check object equality
export function objectEquals(a, b) {
  // TODO: wrap in try catch for error in type
  if (a == null && b == null) {
    return true;
  } else if (a == null || b == null) {
    return false;
  }

  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  if (aProps.length != bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i];
    if (Array.isArray(a[propName]) && Array.isArray(b[propName])) {
      return arrayEquals(a[propName], b[propName]);
    } else if (typeof a[propName] === "object" && typeof b[propName] === "object") {
      return objectEquals(a[propName], b[propName]);
    } else if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}

export function copyStops(stops) {
  if (Array.isArray(stops)) {
    return stops.map((a) => ({ ...a }));
  }
  console.log("SERVER ERROR: Trying to make a deep copy of a not-array");
}

export function sameCity(city1, city2) {
  return city1.address === city2.address;
}

// mutates notableStops to remove duplicates
export function removeConsecutiveDuplicates(notableStops) {
  let currIndex = 0;
  while (currIndex < notableStops.length - 1) {
    if (sameCity(notableStops[currIndex], notableStops[currIndex + 1])) {
      notableStops.splice(currIndex, 1);
    } else {
      currIndex++;
    }
  }
}

export function getAddressFromLatLng(lat, lon, callback) {
  return Geocode.fromLatLng(lat, lon).then((resp) => {
    if (resp.status == "OK") {
      // TODO: error if not found
      // let city = "";
      // let state = "";
      // let country = "";
      // resp.results[0].address_components.map((info) => {
      //     if (info.types.includes("administrative_area_level_1")) {
      //         state = info.short_name;
      //     } else if (info.types.includes("locality") || info.types.includes("sublocality")) {
      //         city = info.long_name;
      //     } else if (info.types.includes("country")) {
      //         country = info.short_name;
      //     }
      // });
      // callback([city, state, country].join(", "));
      let item = resp.results.find((item) => item.types.includes("locality"));
      if (!item){
        item = resp.results.find((item) => item.types.includes("administrative_area_level_2"));
      }
      callback(item.formatted_address);
    }
  });
}

// Calls the backend getWeather endpoint. returns {snow: #, precip: #}
export function getWeather(lat, lon, dateTime, callback) {
  get("/api/weather", {
    lat: Math.round(lat * 100) / 100,
    lon: Math.round(lon * 100) / 100,
    dateTime: dateTime,
  }).then((r) => {
    callback(r);
  });
}
